import {Injectable} from '@angular/core';
import {RouterStateSnapshot, TitleStrategy} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {User} from '../../shared/models';

@Injectable({providedIn: 'root'})
export class PageTitleStrategy extends TitleStrategy {
  constructor(private readonly title: Title) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot): void {
    const title = this.buildTitle(routerState);
    const user: User | null = localStorage.getItem('currentUser')
      ? JSON.parse(localStorage.getItem('currentUser')!)
      : null;
    if (title !== undefined) {
      if (user && user.organization) {
        if (user?.organization?.isCustomDomainEnabled) {
          this.title.setTitle(`${user?.organization?.name} | ${title}`);
        } else {
          this.title.setTitle(
            `AdClicks | ${user?.organization?.name} | ${title}`
          );
        }
      } else {
        this.title.setTitle(`AdClicks | ${title}`);
      }
    }
  }
}
