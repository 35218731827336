import {
  enableProdMode,
  Injectable,
  ENVIRONMENT_INITIALIZER,
  inject,
  NgZone,
  importProvidersFrom,
} from '@angular/core';
import {
  enableAkitaProdMode,
  DevtoolsOptions,
  akitaDevtools,
} from '@datorama/akita';

import {environment} from './environments/environment';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {HttpInterceptorService} from './app/core/interceptors';
import {
  HAMMER_GESTURE_CONFIG,
  HammerGestureConfig,
  BrowserModule,
  HammerModule,
  bootstrapApplication,
} from '@angular/platform-browser';
import {
  OverlayContainer,
  FullscreenOverlayContainer,
} from '@angular/cdk/overlay';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
} from '@ngx-translate/core';
import {AppComponent} from './app/app.component';
import {registerLocaleData} from '@angular/common';

import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import localeEs from '@angular/common/locales/es';
import {catchError, from, Observable, of} from 'rxjs';
import * as Hammer from 'hammerjs';
import FusionCharts from 'fusioncharts';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {CustomMatPaginatorInt} from './app/shared/helpers';
import {provideLottieOptions} from 'ngx-lottie';
import player from 'lottie-web';
import {MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';
import { provideRouter, TitleStrategy } from "@angular/router";
import {APP_ROUTES} from './app/app.routes';
import { PageTitleStrategy } from "./app/core/services/title-strategy.service";

/* i18n */
registerLocaleData(localeEs);
registerLocaleData(localeEn);
registerLocaleData(localeFr);

export class LazyTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<unknown> {
    return from(import(`./i18n/${lang}.json`)).pipe(catchError(() => of({})));
  }
}

@Injectable()
export class HammerConfig extends HammerGestureConfig {
  // Old Config preserved just in case.
  // override overrides = {
  //   swipe: { direction: Hammer.DIRECTION_ALL },
  // };
  override overrides = {
    'swipe': {direction: Hammer.DIRECTION_HORIZONTAL},
    'pinch': {enable: false},
    'rotate': {enable: false},
    'pan': {enable: false},
  };
}

export function provideAkitaDevtools(
  options: Partial<DevtoolsOptions> = {}
): any {
  return {
    provide: ENVIRONMENT_INITIALIZER,
    multi: true,
    useFactory() {
      return () => {
        akitaDevtools(inject(NgZone), options);
      };
    },
  };
}

FusionCharts.options['license']({
  key: environment.fusionChartsKey,
  creditLabel: false,
});

const disableAnimations =
  !('animate' in document.documentElement) ||
  (navigator && /iPhone OS (8|9|10|11|12)_/.test(navigator.userAgent)) ||
  (navigator && /iPad OS (8|9|10|11|12)_/.test(navigator.userAgent));

if (environment.production) {
  enableProdMode();
  enableAkitaProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(APP_ROUTES),
    {provide: TitleStrategy, useClass: PageTitleStrategy},
    importProvidersFrom(
      BrowserModule,
      HammerModule,
      BrowserAnimationsModule.withConfig({disableAnimations}),
      TranslateModule.forRoot({
        defaultLanguage: 'en',
        loader: {
          provide: TranslateLoader,
          useClass: LazyTranslateLoader,
        },
      })
    ),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerConfig,
    },
    {provide: OverlayContainer, useClass: FullscreenOverlayContainer},
    provideAkitaDevtools(),
    provideHttpClient(withInterceptorsFromDi()),
    {provide: MAT_SNACK_BAR_DATA, useValue: {}},
    {
      provide: MatPaginatorIntl,
      useClass: CustomMatPaginatorInt,
      deps: [TranslateService],
    },
    provideLottieOptions({
      player: () => player,
    }),
  ],
}).catch((err) => console.error(err));
